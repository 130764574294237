.input_wrapper {
  position: relative;
  background-color: var(--color-white);
  border: 1px solid var(--color-grey-400);
  border-radius: 5px;
  height: 50px;
  min-height: 50px;
  transition: border-color .3s ease-in-out;

  &.no_left_border {
    border-left: none;
    border-radius: 0 5px 5px 0;
  }

  &.error {
    border-color: var(--color-error);
  }

  label {
    position: absolute;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-black-600);
    cursor: text;
    transition: top .3s ease-in-out, transform .3s ease-in-out, font-size .3s ease-in-out;

    span {
      color: var(--color-secondary);
    }
  }

  input {
    width: 100%;
    height: 48px;
    min-height: 48px;
    padding: 0 20px;
    border: none;
    border-radius: 5px;
    background-color: transparent;
    color: var(--color-black-700);
    transition: padding-top .3s ease-in-out;
    appearance: none;

    &:focus, &.has_value, &:not(:placeholder-shown) {
      padding-top: 15px;

      & ~ label {
        top: 8px;
        transform: translateY(0);
        font-size: var(--fs-10);
      }
    }

    &::placeholder {
      opacity: 0;
    }

    &[type=password] {
      padding-right: 60px;
    }
  }

  label, input {
    font-size: var(--fs-14);
    font-weight: 500;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.view_pass {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-black-700);
  cursor: pointer;
  transition: color .3s ease-in-out;

  &:hover, &.active {
    color: var(--color-secondary);
  }
}