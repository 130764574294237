@import '/styles/variables.scss';
@import '/styles/helpers/responsive.scss';

.login_wrapper {
  width: 100%;
  min-height: 100vh;

  @include mobile {
    flex-direction: column;
    background-color: var(--color-white);
    min-height: 100vh;
    min-height: -webkit-fill-available; /* mobile viewport bug fix */
  }

  & > div {
    width: 50%;
    padding-top: calc(#{$header-height} + 20px);
    flex: 1 1 auto;

    @include mobile {
      width: 100%;
    }
  }
}

.login_block {
  width: 100%;
  max-width: 480px;
  padding: 40px;

  @include mobile {
    padding: 0 20px 20px;
  }
}

.logo_block {
  background-color: var(--color-primary-700);

  svg {
    opacity: 0.4;
  }

  @include mobile {
    display: none;
  }
}

.mobile_order_1 {
  @include mobile {
    order: 1;
  }
}

.mobile_order_2 {
  @include mobile {
    order: 2;
  }
}

.mobile_order_3 {
  @include mobile {
    order: 3;
  }
}